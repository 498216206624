import React from "react";
import Divider from "./Divider";
import config from "../config/index.json";

function Customers() {
  const { customers } = config;

  return (
    <section id="customers" className={`bg-background py-8`}>
      <div className={`container max-w-5xl mx-auto m-8`}>
        <h1
          className={`w-full my-2 text-5xl font-bold leading-tight text-center text-primary`}
        >
          {customers.title.split(" ").map((word, index) => (
            <span
              key={index}
              className={index % 2 ? "text-primary" : "text-border"}
            >
              {word}{" "}
            </span>
          ))}
        </h1>

        <Divider />

        {customers.items.map((customer, index) => {
          return (
            <div className={`flex flex-wrap`} key={index}>
              <div className={`w-full sm:w-1/2 p-6`}>
                <img
                  className="rounded-full w-5/6"
                  src={customer?.img}
                  alt={customer?.title}
                />
              </div>
              <div className={`w-5/6 sm:w-1/2 p-6 mt-20`}>
                <h3
                  className={`text-3xl text-gray-800 font-bold leading-none mb-3`}
                >
                  {customer?.title}
                </h3>
                <p className={`text-gray-600`}>{customer?.description}</p>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
}

export default Customers;
