import React, { useState } from "react";
import { Markup } from "react-render-markup";
import PropTypes from "prop-types";

function ProductDetails({
  showButtonTitle,
  title,
  text,
  img,
  closeText,
  actionText,
  actionURL,
}) {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <button
        className={`w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-background bg-primary hover:bg-border hover:text-primary md:py-4 md:text-lg md:px-10`}
        onClick={() => setShowModal(true)}
      >
        {showButtonTitle}
      </button>
      {showModal ? (
        <>
          <div className="flex mt-6 justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-7xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                  <h3 className="text-3xl font=semibold">
                    <strong>{title}</strong>
                  </h3>
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    {closeText}
                  </button>
                </div>
                <div className="relative p-6 flex-auto">
                  <img src={img} alt={title} />
                </div>
                <div className="relative p-6 flex-auto">
                  {text instanceof Array ? (
                    text.map((line, index) => {
                      return (
                        <p key={index}>
                          <Markup markup={line} />
                        </p>
                      );
                    })
                  ) : (
                    <Markup markup={text} />
                  )}
                </div>
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <a
                    href={actionURL}
                    className="flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md border-primary text-secondary bg-background hover:bg-border hover:text-primary md:py-4 md:text-lg md:px-10"
                    type="button"
                  >
                    {actionText}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}

ProductDetails.propTypes = {
  showButtonTitle: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  img: PropTypes.string,
};

export default ProductDetails;
