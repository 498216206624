import React from "react";
import Divider from "./Divider";
import config from "../config/index.json";
import ProductDetails from "./ProductDetails";

function Product() {
  const { products } = config;

  return (
    <section id="products" className={`bg-background py-8`}>
      <div className={`container max-w-5xl mx-auto m-8`}>
        <h1
          className={`w-full my-2 text-5xl font-bold leading-tight text-center text-primary`}
        >
          {products.title.split(" ").map((word, index) => (
            <span
              key={index}
              className={index % 2 ? "text-primary" : "text-border"}
            >
              {word}{" "}
            </span>
          ))}
        </h1>
        <Divider />
        {products.items.map((product, index) => {
          return (
            <div className={`flex flex-wrap`} key={index}>
              <div className={`w-full sm:w-1/2 p-6`}>
                <img
                  className="w-5/6"
                  src={product?.img}
                  alt={product?.title}
                />
              </div>
              <div className={`w-5/6 sm:w-1/2 p-6 mt-20`}>
                <h3
                  className={`text-3xl text-gray-800 font-bold leading-none mb-3`}
                >
                  {product?.title}
                </h3>
                <p className={`text-gray-600`}>{product?.description}</p>
                {product?.details && (
                  <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                    <div className="rounded-md shadow">
                      <ProductDetails
                        showButtonTitle={product.details.showButtonTitle}
                        title={product.title}
                        text={product.details.text}
                        img={product.details.img}
                        closeText={product.details.closeText}
                        actionText={product.action.text}
                        actionURL={product.action.href}
                      />
                    </div>
                  </div>
                )}
                {product?.action && (
                  <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                    <div className="rounded-md shadow">
                      <a
                        href={product.action.href}
                        className={`w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md border-primary text-secondary bg-background hover:bg-border hover:text-primary md:py-4 md:text-lg md:px-10`}
                      >
                        {product.action.text}
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
}

export default Product;
