import React from "react";
import "./App.css";
import Header from "./components/Header";
import MainHero from "./components/MainHero";
import MainHeroImage from "./components/MainHeroImage";
import LazyShow from "./components/LazyShow";
import Products from "./components/Products";
import Customers from "./components/Customers";
import Contact from "./components/Contacts";
import "./styles/main.css";

function App() {
  return (
    <div className={`bg-background grid gap-y-16 overflow-hidden`}>
      <div className={`relative bg-background`}>
        <div className="max-w-7xl mx-auto">
          <div
            className={`relative z-10 pb-8 bg-background sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32`}
          >
            <Header />
            <MainHero />
          </div>
        </div>
        <MainHeroImage />
      </div>
      <LazyShow>
        <Products />
      </LazyShow>
      <LazyShow>
        <Customers />
      </LazyShow>

      <LazyShow>
        <Contact />
      </LazyShow>
    </div>
  );
}

export default App;
