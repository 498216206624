import React from "react";
import config from "../config/index.json";
import Divider from "./Divider";

function Contact() {
  const { contacts, company } = config;
  const { phone, email } = contacts;
  const { name, logo } = company;

  return (
    <section
      id="contacts"
      className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28"
    >
      <h1
        className={`w-full my-2 text-5xl font-bold leading-tight text-center text-primary`}
      >
        <span className="text-border">Контакты</span>
      </h1>
      <Divider />
      <div className="sm:text-center lg:text-left">
        <h1 className="text-xl tracking-tight font-extrabold text-gray-900 sm:text-2xl md:text-3xl">
          <span className="block xl:inline">{name}</span>{" "}
        </h1>
        <h2 className="text-xl tracking-tight font-extrabold text-gray-900 sm:text-2xl md:text-3xl">
          <p className={`block text-primary xl:inline`}>{phone}</p>
        </h2>
        <div className="mb-10 mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
          <div className="mt-3 sm:mt-0 sm:ml-3">
            <a
              href={email}
              className={`w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md border-primary text-secondary bg-background hover:bg-border hover:text-primary md:py-4 md:text-lg md:px-10`}
            >
              Написать нам
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
